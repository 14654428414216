body {
    background: #AED9E0;
    font-family: sans-serif;
    max-height: 100%;
    max-width: 100%;
}

hr.main-line {
    display: block; height: 1px;
    border: 0;
    border-top: 5px solid #B8F2E6;
    margin-right: 10%;
    position: relative;
    animation-name: expand;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

.anchor {
    margin-top: 10%;
    margin-left: 10%;
}

.name {
    font-size: 3em;
    font-weight: bold;
    color: #5E6472;
    position: relative;
    animation-name: slide-intro-from-left;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

.menu {
    margin-left: 10%;
    display: grid;
    justify-content: flex-start;
    align-items: start;
    height: 50vh;
}

.menu > div {
      text-align: start;
      color: #5E6472;
      padding: 20px 0;
      font-size: 30px;
}

.menu a {
    text-decoration: none;
    color: #5E6472;
    font-size: 1.25em;
    position: relative;
    animation-name: slide-intro-from-right;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

.menu a:visited {
    color: inherit;
}

.menu div {
    position: relative;

}

.menu div::after {
    content: '';
    display: inline-block;
    height: 100px;
    background: #FFA69E;
    position: absolute;
    left: -.75em;
    top: 0;
    margin-left: 0;
    margin-top: 0;
    width: 0%;
    height: 100%;
    z-index: -1;
    border-radius: 10% 10% 10% 10%;
}

.menu div:hover::after {
    transition: 0.5s;
    animation-name: highlight;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    width: 140%;
}

.menu div:hover {
    color: #FAF3DD;
    margin-left: 20px;
    animation-name: shift-right;
    animation-duration: 0.3s;
}

@keyframes highlight {
    0% {
        width: 0%;
        opacity: 0;
    }

    100% {
        width: 140%;
        opacity: 1;
    }
}

@keyframes shift-right {
    0% { margin-left: 0px; }
    20% { margin-left: 4px; }
    40% { margin-left: 8px; }
    60% { margin-left: 12px; }
    80% { margin-left: 16px; }
    100% { margin-left: 20px;  }
}

@keyframes slide-intro-from-left {
    0% {
        left: -10em;
    }

    100% {
        left: 0;
    }
}

@keyframes slide-intro-from-right {
    0% {
        right: -45em;
    }

    100% {
        right: 0;
    }
}

@keyframes expand {
    0% {
        margin-right: 100%;
        margin-left: 100%;
    }

    100% {
        margin-right: 10%;
        margin-left: 0;
    }
}