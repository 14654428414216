body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    background: #AED9E0;
    font-family: sans-serif;
    max-height: 100%;
    max-width: 100%;
}

hr.main-line {
    display: block; height: 1px;
    border: 0;
    border-top: 5px solid #B8F2E6;
    margin-right: 10%;
    position: relative;
    -webkit-animation-name: expand;
            animation-name: expand;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

.anchor {
    margin-top: 10%;
    margin-left: 10%;
}

.name {
    font-size: 3em;
    font-weight: bold;
    color: #5E6472;
    position: relative;
    -webkit-animation-name: slide-intro-from-left;
            animation-name: slide-intro-from-left;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

.menu {
    margin-left: 10%;
    display: grid;
    justify-content: flex-start;
    align-items: start;
    height: 50vh;
}

.menu > div {
      text-align: start;
      color: #5E6472;
      padding: 20px 0;
      font-size: 30px;
}

.menu a {
    text-decoration: none;
    color: #5E6472;
    font-size: 1.25em;
    position: relative;
    -webkit-animation-name: slide-intro-from-right;
            animation-name: slide-intro-from-right;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

.menu a:visited {
    color: inherit;
}

.menu div {
    position: relative;

}

.menu div::after {
    content: '';
    display: inline-block;
    height: 100px;
    background: #FFA69E;
    position: absolute;
    left: -.75em;
    top: 0;
    margin-left: 0;
    margin-top: 0;
    width: 0%;
    height: 100%;
    z-index: -1;
    border-radius: 10% 10% 10% 10%;
}

.menu div:hover::after {
    transition: 0.5s;
    -webkit-animation-name: highlight;
            animation-name: highlight;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    width: 140%;
}

.menu div:hover {
    color: #FAF3DD;
    margin-left: 20px;
    -webkit-animation-name: shift-right;
            animation-name: shift-right;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
}

@-webkit-keyframes highlight {
    0% {
        width: 0%;
        opacity: 0;
    }

    100% {
        width: 140%;
        opacity: 1;
    }
}

@keyframes highlight {
    0% {
        width: 0%;
        opacity: 0;
    }

    100% {
        width: 140%;
        opacity: 1;
    }
}

@-webkit-keyframes shift-right {
    0% { margin-left: 0px; }
    20% { margin-left: 4px; }
    40% { margin-left: 8px; }
    60% { margin-left: 12px; }
    80% { margin-left: 16px; }
    100% { margin-left: 20px;  }
}

@keyframes shift-right {
    0% { margin-left: 0px; }
    20% { margin-left: 4px; }
    40% { margin-left: 8px; }
    60% { margin-left: 12px; }
    80% { margin-left: 16px; }
    100% { margin-left: 20px;  }
}

@-webkit-keyframes slide-intro-from-left {
    0% {
        left: -10em;
    }

    100% {
        left: 0;
    }
}

@keyframes slide-intro-from-left {
    0% {
        left: -10em;
    }

    100% {
        left: 0;
    }
}

@-webkit-keyframes slide-intro-from-right {
    0% {
        right: -45em;
    }

    100% {
        right: 0;
    }
}

@keyframes slide-intro-from-right {
    0% {
        right: -45em;
    }

    100% {
        right: 0;
    }
}

@-webkit-keyframes expand {
    0% {
        margin-right: 100%;
        margin-left: 100%;
    }

    100% {
        margin-right: 10%;
        margin-left: 0;
    }
}

@keyframes expand {
    0% {
        margin-right: 100%;
        margin-left: 100%;
    }

    100% {
        margin-right: 10%;
        margin-left: 0;
    }
}
.circle{
  position: absolute;
  border-radius: 50%;
  background: white;
  -webkit-animation: ripple 15s infinite;
          animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
  z-index: -950;
}

.small{
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
}

.medium{
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large{
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge{
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

.xxlarge{
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.shade1{
  opacity: 0.2;
}
.shade2{
  opacity: 0.5;
}

.shade3{
  opacity: 0.7;
}

.shade4{
  opacity: 0.8;
}

.shade5{
  opacity: 0.9;
}

@-webkit-keyframes ripple{
  0%{
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  
  50%{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  100%{
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

@keyframes ripple{
  0%{
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  
  50%{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  100%{
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}
